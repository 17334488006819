@import './themes.sass';
a {
    text-decoration: none !important;
}
#pageContactUs {
    background-color: var(--bg__primary);
    color: var(--color__text);
    font-family: 'Open Sans', ans-serif;
    min-height: 100vh;
    overflow: hidden;
    .back-arrow {
        color: #000;
    }
    .logo {
        img {
            display: block;
            width: 100%;
            max-width: 80px;
            margin: 20px 0 50px;
        }
    }
    .title {
        font-weight: 500;
        font-size: 35px;
        line-height: 45px;
        img {
            width: 55px;
        }
    }
    .opciones {
        margin: 50px 0;
        .card {
            flex-direction: row;
            min-height: 140px;
            margin-bottom: 40px;
            border-radius: 2px;
            border-color: transparent;
            box-shadow: 0 0 10px rgba(#000, 0.1);
            figure {
                padding: 0 20px;
                margin-bottom: 0;
                svg {
                    color: var(--bg__btn);
                }
            }
            .content {
                padding: 10px 15px;
                h3 {
                    color: var(--color__text__light);
                    font-size: 18px;
                    display: block;
                    margin-bottom: 0;
                }
            }
            &:hover {
                background: var(--bg__btn);
                box-shadow: 0 0 10px rgba(#000, 0.2);
                figure {
                    svg {
                        color: #fff;
                    }
                }
                .content {
                    h3 {
                        color: #fff;
                    }
                }
            }
        }
    }
    .contacto {
        width: 100%;
        margin: 50px auto 50px;
        max-width: 1080px;
        .title {
            max-width: 700px;
            font-size: 30px;
        }
        form {
            display: block;
            width: 100%;
            max-width: 500px;
            margin-top: 50px;
            .wrapInput {
                width: 100%;
                position: relative;
                margin-bottom: 30px;
                .inputCus {
                    width: 100%;
                    display: block;
                    border: none;
                    border-bottom: solid 1px rgba($color: #000, $alpha: 0.4);
                    color: var(--color__text__light);
                    background: none;
                    outline: none !important;
                    box-shadow: none;
                    font-size: 20px;
                    font-weight: 600;
                    padding: 10px 0;
                    &::placeholder {
                        color: rgba($color: #000, $alpha: 0.5);
                    }
                }
                .areaCus {
                    width: 100%;
                    display: block;
                    border: none;
                    border-bottom: solid 1px rgba($color: #000, $alpha: 0.4);
                    color: #000;
                    background: none;
                    outline: none !important;
                    box-shadow: none;
                    font-size: 20px;
                    font-weight: 600;
                    padding: 10px 0;
                    height: 100px;
                    resize: none;
                    &::placeholder {
                        color: rgba($color: #000, $alpha: 0.5);
                    }
                }
                .error {
                    color: #f00;
                    font-size: 12px;
                    position: absolute;
                    top: 100%;
                }
            }
            button {
                background: var(--bg__btn);
                color: #fff;
                display: block;
                width: 100%;
                margin-top: 50px;
                height: 48px;
                cursor: pointer;
                font-size: 20px;
            }
            .iti {
                display: block;
                width: 100%;
                color: var(--color__text__light);
                .inputCus {
                    padding-left: 60px;
                }
            }
            .iti--separate-dial-code .iti__selected-dial-code {
                font-size: 20px;
                font-weight: 600;
            }
        }
    }
    .itemSelected {
        margin: 50px auto 50px;
        max-width: 1080px;
        h3 {
            font-size: 30px;
            display: flex;
            align-items: center;
            img {
                display: block;
                width: 50px;
                margin-left: 10px;
            }
        }
        .back {
            width: 24px;
            margin-bottom: 50px;
        }
        .faqs {
            width: 100%;
            max-width: 600px;
            margin-top: 70px;
            .faq {
                border-top: solid 1px #919baf;
                padding: 20px 0;
                .titleF {
                    font-size: 18px;
                    font-weight: 600;
                    cursor: pointer;
                    padding-right: 20px;
                    position: relative;
                    .angle {
                        width: 15px;
                        position: absolute;
                        right: 0;
                        top: 0px;
                        &.active {
                            transform: rotate(-180deg);
                        }
                    }
                }
                .content {
                    color: var(--color__text);
                    margin-top: 20px;
                    font-size: 16px;
                    display: none;
                    a {
                    }
                    &.active {
                        display: block;
                    }
                    ul {
                        padding-left: 14px;
                        margin-top: 10px;
                    }
                }
            }
        }
        .colorRed {
            color: #f00;
        }
    }
}

.dark {
    #pageContactUs {
        background: #000;
        color: #fff;
        .back-arrow {
            color: #fff;
        }
        .contacto {
            .inputCus {
                width: 100%;
                border-bottom: solid 1px rgba($color: #fff, $alpha: 0.4) !important;
                color: #fff !important;
                &::placeholder {
                    color: rgba($color: #fff, $alpha: 0.5) !important;
                }
            }
            .areaCus {
                border-bottom: solid 1px rgba($color: #fff, $alpha: 0.4) !important;
                color: #fff !important;
                &::placeholder {
                    color: rgba($color: #fff, $alpha: 0.5) !important;
                }
            }
        }
    }
}

.iti--separate-dial-code .iti__selected-flag {
    background: none !important;
}

.bg-btn-ppal {
    background: var(--bg__btn) !important;
    outline: none !important;
}

.titleCode {
    font-weight: 500;
    font-size: 30px;
    color: #1e1e1e;
}
.confirmCode {
    //background: #f00 !important;
}

#wrapCorreoCompra {
    margin-top: 10px;
    input {
        border: 1px solid var(--color__text__light);
        display: block;
        width: 100%;
        border-radius: 4px;
        line-height: 38px;
        margin-bottom: 30px;
        outline: none !important;
        box-shadow: none !important;
        padding: 0 10px;
    }
    #wrap-sec {
        span {
            font-size: 14px;
            color: #f00;
            margin-bottom: 20px;
        }
    }
}
.bot_container{
    border-radius: 6px;
    border: 1px solid #DBDFE8;
    background: #FFF;
    padding: 25px;
    gap:25px ;
    display: flex;
    flex-direction: column;
    >div{
        background: #F4F4F4;
        padding: 25px;
        color: #000;
        font-size: 16px;
        font-weight: 500;
    }
    &.footer_imput{
        flex-direction: row;
        margin-top: 20px;
        input{
            border: none;
            width: calc(100% - 50px);
        }
    }
}

.to_help_tittle{
    display: inline-flex;
    align-items: center;
    gap: 18px;
    color: #353B47;
    font-weight: 600;
    line-height: 30px;
}
.link_to_help{
    border-radius: 6px;
    border: 1px solid var(--bg__btn);
    line-height: 41px;
    display: inline-block;
    padding: 0 16px;
    color: var(--bg__btn);
}

$placeholder:#F0F0F0;
$shadow:0 0 10px rgba(139,158,167,.16);
$text-error:#ff0000;
$color6:var(--bg__btn);
.modalNew {
    position: fixed;
    z-index: 99999;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    overflow: auto;
    & > .wrap {
      padding: 30px;
      width: 100%;
      max-height: 100%;
      overflow: auto;
      & > .content {
        .icon-cerrar {
          position: absolute;
          right: 15px;
          top: 15px;
          z-index: 3000;
          font-size: 16px;
          cursor: pointer;
        }
        overflow: hidden;
        min-height: 100px;
        min-width: 300px;
        margin: 0px auto;
        max-width: calc(100% - 20px);
        background: white;
        border-radius: 12px;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 560px;
        gap: 10px;
        padding: 50px 80px;
        .lm--title_new{
          font-weight: 600;
          line-height: 23px;
          font-size: 20px;
          width: 100%;
          text-align: center;
          font-family: 'karol-g','Open Sans', sans-serif;
        }
        label{
          font-size: 16px;
          line-height: 24px;
        }
        input{
          background: white;
          border: none;
          height: 46px;
          line-height: 46px;
          border-radius: 6px;
          padding: 0 10px;
          font-size: 14px;
          border: 1px solid #ece3e5;
        }
        .image {
          overflow: hidden;
          max-height: 100%;
          position: relative;
          max-width: 430px;
          figure.ppal {
            display: flex;
            align-items: center;
            align-content: center;
            // background: url(../images/placeholderImg.svg) no-repeat;
            background-size: cover;
            background-position: center center;
            // min-height: 700px;
            img {
              width: 100%;
              border-radius: 0;
              max-height: 700px;
              object-fit: cover;
            }
          }
          .thumbnails{
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 80px;
            padding: 5px;
            figure{
              margin-bottom: 10px;
              box-shadow: $shadow;
              cursor: pointer;
              padding:1px;
              border-radius: 4px;
              background: #FFFFFF;
              img{
                display: block;
                width: 100%;
                border-radius: 4px;
              }
            }
          }
        }
        .detalle {
          overflow: hidden;
          position: relative;
          .header {
            height: 150px;
            padding: 35px 25px;
            border-bottom: solid 1px $placeholder;
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            p {
              
              font-size: 14px;
              margin-bottom: 0;
            }
          }
          .opciones {
            overflow: auto;
            margin-top: 150px;
            margin-bottom: 70px;
            max-height: 100%;
            max-height: calc(100% - 220px);
            max-height: 400px;
            .customOption {
              padding: 25px;
              border-bottom: solid 1px $placeholder;
              .top {
                .textSubTitle.errorV{
                  color: $text-error;
                }
                h4 {
                  margin-bottom: 20px;
                  font-size: inherit;
                  font-family: inherit;
                }
              }
              .bodyOptions {
                .option {
                  label {
                    font-style: 16px;
                    
                  }
                  .precio {
                    font-style: 16px;
                    font-weight: 600;
                  }
                }
              }
            }
            .errorVar{
              font-size: 12px;
              color: $text-error;
            }
          }
          .footer {
            height: 70px;
            position: absolute;
            bottom: 0;
            background: #fff;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: auto;
            padding: 5px 20px;
            width: 100%;
            .buttonsModal {
              display: flex;
              margin-right: 10px;
              i {
                font-size: 32px;
                height: 32px;
                line-height: 32px;
                font-weight: 600;
                cursor: pointer;
                &:before {
                  color: $color6;
                }
              }
              span {
                font-size: 18px;
                font-weight: bold;
                margin: 0 10px;
                line-height: 32px;
              }
            }
            button.buttonAdd {
              font-size: 14px;
              width: 120px;
              height: 40px;
              line-height: 38px;
              color: #fff;
              font-weight: 600;
              text-align: center;
              border: solid 1px $color6;
              border-radius: 8px;
              background: $color6;
              display: block;
              &.Eliminar{
                border-color:$text-error;
                background:$text-error;
              }
            }
            button.action {
              background: #6f706f;
              display: block;
              width: 100%;
              border: none;
              max-width: 260px;
              margin: 0 auto;
              height: 40px;
              line-height: 40px;
              padding: 0 10px;
              border-radius: 8px;
              color: #fff;
              font-size: 16px;
              font-weight: bold;
            }
            .monto {
              font-size: 18px;
              font-weight: bold;
              margin-left: auto;
            }
          }
        }
        .ld--btn{
          margin-top: 10px;
          background: var(--color__secondary);
        }
      }
    }
  }