// Colors light
$bg__primary: #F1F2F4;
$color__text: #24292E;
$color__text__light: #8E8E93;
$bg__btn: #db2280;
$color_secondary: #5bb8c9;
$bg__btn__help: #fff;
$bg__btn__disabled: #8E8E93;
$color__text__btn: white;
$bg__card: rgba(255,255,255,.5);
$bg__footer: #000;

// Colors dark
$bg__primary--dark: #000103;
$color__text--dark: #FFFFFA;
$color__text__light--dark: #8E8E93;
$bg__btn--dark: #db2280;
$color_secondary--dark: #5bb8c9;
$bg__btn__help__dark: rgba(255,255,255,.1);
$color__text__btn--dark: #ffffff;
$bg__card_dark: rgba(255,255,255,.1);
$bg__footer--dark: #1e1e1e;

// Theme light
.theme {
  --bg__primary: #{$bg__primary};
  --color__text: #{$color__text};
  --color__text__light: #{$color__text__light};
  --bg__btn: #{$bg__btn};
  --color__secondary: #{$color_secondary};
  --bg__btn__help: #{$bg__btn__help};
  --bg__btn__disabled: #{$bg__btn__disabled};
  --color__text__btn: #{$color__text__btn};
  --bg__card: #{$bg__card};
  --bg__footer: #{$bg__footer};
  // Theme dark
  &--dark {
    --bg__primary: #{$bg__primary--dark};
    --color__text: #{$color__text--dark};
    --color__text__light: #{$color__text__light--dark};
    --bg__btn__help: #{$bg__btn__help__dark};
    --bg__btn: #{$bg__btn--dark};
    --color__secondary: #{$color_secondary--dark};
    --bg__card:  #{$bg__card_dark};
    --color__text__btn: #{$color__text__btn--dark};
    --bg__footer: #{$bg__footer--dark}; } }
