.container-custom-tm{
    max-width: 100%;
    margin: 0 auto;
    width: 100%;
    padding: 0 10px;
}
@media (min-width: 576px) {
    .container-custom-tm{
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container-custom-tm{
        padding: 0 0px;
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container-custom-tm{
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container-custom-tm{
        max-width: 1024px;
    }
}

@media (min-width: 1440px ) {
    .container-custom-tm{
        max-width: 1400px;
    }
}
@media (min-width: 1580px ) {
    .container-custom-tm{
        max-width: 1530px;
    }
}