@import '../../styles/themes.sass';

#modalMap {
    color: #000;
    position: fixed;
    overflow: auto;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    padding: 0 30px;

    @media (max-width: 680px) {
        padding: 0 15px;
    }

    z-index: 9999;

    .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        background: rgba(#000, 0.7);
    }

    .labelAgotado {
        z-index: 99999;
        transform: translateX(-50%);
        font-family: H-B;
        color: rgb(120, 120, 120);
    }

    .nameZona {
        z-index: 99999;
        transform: translateX(-50%);
        font-family: 'H-B';
        letter-spacing: 0.02em;
        color: #fff;
        font-size: 28px;
        pointer-events: none;

        @media (max-width: 680px) {
            font-size: 18px;
        }

        small {
            font-size: 18px;
            letter-spacing: 0;

            @media (max-width: 680px) {
                font-size: 14px;
            }
        }
    }

    svg {
        width: 100% !important;
        max-width: 600px !important;
        margin-left: auto;
        margin-right: auto;
    }

    .active {
        opacity: 0.8;
    }
}

#modalContent {
    z-index: 10;
    display: block;
    position: relative;
    padding: 30px;
    margin: 30px auto;
    background: #fff;
    border-radius: 10px;
    width: 100%;
    max-width: 710px;

    h3 {
        color: #000;
    }

    @media (max-width: 680px) {
        padding: 30px 10px;
    }

    @media (max-width: 992px) {
        max-width: 710px;
    }

    @media (max-width: 767px) {
        max-width: 510px;
    }

    .cerrar {
        position: absolute;
        right: 0px;
        top: 0px;
        padding: 10px;
        cursor: pointer;
    }

    .infoSec {
        position: absolute;
        left: 30px;
        bottom: 20px;

        & > div {
            font-size: 16px;

            b {
                display: block;
                margin-right: 10px;
            }
        }
    }

    #scroll-svg {
        width: 100%;
        overflow-x: auto;
    }
}

#mapaC {
    width: 100%;
    display: block;
    min-width: 600px;
    max-height: 800px;
}




#gold,#expo,#grada-norte,#grada-sur,
#EXPKANY,#DPM,#CONFIESO,#HOYYAMEVOY
{
    cursor: pointer;
}

// *********************************************************************


#mapaC{

	.cls-1 {
        stroke: #999;
      }

      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8 {
        stroke-miterlimit: 10;
      }

      .cls-1, .cls-4 {
        fill: none;
      }

      .cls-9 {
        fill: #fff;
      }

      .cls-2 {
        fill: #f2f2f2;
      }

      .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8 {
        stroke: #fff;
      }

      .cls-3 {
        fill: #e6e6e6;
      }

      .cls-10 {
        isolation: isolate;
      }

      .cls-5 {
        fill: #2c44f5;
        stroke-width: 2px;
      }

      .cls-6 {
        fill: #383838;
        stroke-width: .25px;
      }

      .cls-7 {
        fill: #bf3ef3;
      }

      .cls-8 {
        fill: #f3983e;
      }
}





#mapaV{
	.st0{fill:#363636;stroke:#FFFFFF;stroke-width:3;stroke-miterlimit:10;}
	.st1{fill:#FAB3CF;stroke:#FFFFFF;stroke-miterlimit:10;}
	.st2{fill:#FEE9F2;stroke:#FFFFFF;stroke-miterlimit:10;}
	.st3{fill:#B35C7F;stroke:#FFFFFF;stroke-miterlimit:10;}
	.st4{fill:#700070;stroke:#FFFFFF;stroke-miterlimit:10;}
	.st5{fill:#FFFFFF;}
	.st6{font-family:'Arial-BoldMT';}
	.st7{font-size:30.09px;}
	.st8{fill:#999999;}
	.st9{font-size:38px;}
	.st10{letter-spacing:-1;}
	.st11{letter-spacing:-2;}
	.st12{fill:none;stroke:#CCCCCC;stroke-miterlimit:10;}
}